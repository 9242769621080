import axios from '@/common/axios'
import qs from 'qs'

export function addDeliveryOrder (data) {
  return axios({
    method: 'post',
    url: '/storage/deliveryOrder/add',
    data: qs.stringify(data)
  })
}

export function deleteDeliveryOrder (id) {
  return axios({
    method: 'delete',
    url: '/storage/deliveryOrder/delete/' + id
  })
}

export function updateDeliveryOrder (data) {
  return axios({
    method: 'put',
    url: '/storage/deliveryOrder/update',
    data: qs.stringify(data)
  })
}

export function selectDeliveryOrderInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/deliveryOrder/info/' + id
  })
}

export function selectDeliveryOrderList (query) {
  return axios({
    method: 'get',
    url: '/storage/deliveryOrder/list',
    params: query
  })
}

export function reviewDeliveryOrder (data) {
  return axios({
    method: 'put',
    url: '/storage/deliveryOrder/updateReview',
    data: qs.stringify(data)
  })
}

export function cancelDeliveryOrder (id) {
  return axios({
    method: 'put',
    url: '/storage/deliveryOrder/cancel/' + id
  })
}

export function selectDeliveryOrderByNo (shippingOrderNo) {
  return axios({
    method: 'get',
    url: '/storage/deliveryOrder/selectByNo/' + shippingOrderNo
  })
}
