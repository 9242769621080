<template>
  <div id="deliveryOrder">
    <el-dialog
      :title="deliveryOrderFormTitle"
      width="1500px"
      :visible.sync="deliveryOrderDialogVisible"
      :close-on-click-modal="false"
      @close="deliveryOrderDialogClose"
    >
      <el-form
        ref="deliveryOrderFormRef"
        :model="deliveryOrderForm"
        :rules="deliveryOrderFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <!-- <el-col :span="8">
            <el-form-item label="订单号" prop="orderNo">
              <el-input v-model="deliveryOrderForm.orderNo" placeholder="请输入订单号">
                <i slot="suffix" class="el-input__icon el-icon-success" @click="selectNotice" />
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="内外销" prop="type">
              <el-radio-group
                v-model="deliveryOrderForm.type"
                :disabled="
                  deliveryOrderFormTitle !== '新增出货单' &&
                    deliveryOrderFormTitle !== '修改出货单' &&
                    deliveryOrderFormTitle !== '出货单详情'
                "
              >
                <el-radio
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.code"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单号" prop="orderNo">
              <el-select
                v-model="deliveryOrderForm.orderNo"
                placeholder="请选择订单号"
                clearable
                filterable
                @change="selectNotice"
              >
                <el-option
                  v-for="item in noticeList"
                  :key="item.id"
                  :label="item.noticeNo"
                  :value="item.noticeNo"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="shippingOrderNo">
              <el-input
                v-model="deliveryOrderForm.shippingOrderNo"
                placeholder="请输入编号"
                clearable
                :disabled="
                  deliveryOrderFormTitle !== '新增出货单' &&
                    deliveryOrderFormTitle !== '修改出货单' &&
                    deliveryOrderFormTitle !== '出货单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出货日期" prop="shippingDate">
              <el-date-picker
                v-model="deliveryOrderForm.shippingDate"
                placeholder="请选择出货日期"
                value-format="yyyy-MM-dd"
                :disabled="
                  deliveryOrderFormTitle !== '新增出货单' &&
                    deliveryOrderFormTitle !== '修改出货单' &&
                    deliveryOrderFormTitle !== '出货单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户名称" prop="customerName">
              <el-input
                v-model="deliveryOrderForm.customerName"
                placeholder="请输入客户名称"
                clearable
                :disabled="
                  deliveryOrderFormTitle !== '新增出货单' &&
                    deliveryOrderFormTitle !== '修改出货单' &&
                    deliveryOrderFormTitle !== '出货单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收货地址" prop="receivingAddress">
              <el-input
                v-model="deliveryOrderForm.receivingAddress"
                placeholder="请输入收货地址"
                clearable
                :disabled="
                  deliveryOrderFormTitle !== '新增出货单' &&
                    deliveryOrderFormTitle !== '修改出货单' &&
                    deliveryOrderFormTitle !== '出货单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产地" prop="producingArea">
              <el-input
                v-model="deliveryOrderForm.producingArea"
                placeholder="请输入产地"
                clearable
                :disabled="
                  deliveryOrderFormTitle !== '新增出货单' &&
                    deliveryOrderFormTitle !== '修改出货单' &&
                    deliveryOrderFormTitle !== '出货单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="deliveryOrderForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="
                  deliveryOrderFormTitle !== '新增出货单' &&
                    deliveryOrderFormTitle !== '修改出货单' &&
                    deliveryOrderFormTitle !== '出货单详情'
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="
              deliveryOrderFormTitle === '新增出货单' ||
                deliveryOrderFormTitle === '修改出货单'
            "
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="orderDetailList"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
          >
            <vxe-table-column field="productNo" title="产品编号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择产品"
                  clearable
                  filterable
                  @change="materialProductStockChange(row)"
                >
                  <el-option
                    v-for="item in materialProductStockList"
                    :key="item.id"
                    :label="item.productNo"
                    :value="item.productNo"
                  />
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="productName"
              title="产品名称"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="spec"
              title="规格型号"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="batchNo"
              title="批号"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="expirationDate"
              title="失效期"
              :edit-render="{
                name: '$input',
                props: { type: 'date', clearable: true },
              }"
            />
            <vxe-table-column
              field="unit"
              title="单位"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="quantity"
              title="数量"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="unitPrice"
              title="单价"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="amount"
              title="金额"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="productionLicense"
              title="生产许可证"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="productRegistration"
              title="产品注册证"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              v-if="
                deliveryOrderFormTitle === '新增出货单' ||
                  deliveryOrderFormTitle === '修改出货单'
              "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核结果" prop="reviewResult">
              <el-radio-group
                v-model="deliveryOrderForm.reviewResult"
                :disabled="
                  deliveryOrderFormTitle !== '审核出货单' &&
                    deliveryOrderFormTitle !== '出货单详情'
                "
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="deliveryOrderDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deliveryOrderFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input
          v-model="searchForm.shippingOrderNo"
          placeholder="请输入编号"
          clearable
        />
      </el-form-item>
      <el-form-item label="客户名称">
        <el-input
          v-model="searchForm.customerName"
          placeholder="请输入客户名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="内外销" prop="type">
        <el-select v-model="searchForm.type" placeholder="请选择内外销" clearable>
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CC_DELIVERY_ORDER_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="deliveryOrderPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="shippingOrderNo" label="编号" />
      <el-table-column label="出货日期">
        <template slot-scope="scope">
          <span v-if="scope.row.shippingDate">{{
            scope.row.shippingDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="客户名称" />
      <el-table-column prop="receivingAddress" label="收货地址" />
      <el-table-column prop="producingArea" label="产地" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="sender" label="发货人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.sendDate">{{
            scope.row.sendDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{
            scope.row.reviewDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column label="内外销">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">内销</span>
          <span v-if="scope.row.type === 2">外销</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="
              checkPermission(['CC_DELIVERY_ORDER_DELETE']) &&
                scope.row.status === 1
            "
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="
              checkPermission(['CC_DELIVERY_ORDER_UPDATE']) &&
                scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="
              checkPermission(['CC_DELIVERY_ORDER_REVIEW']) &&
                scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            v-if="
              checkPermission(['CC_DELIVERY_ORDER_CANCEL']) &&
                scope.row.status === 2
            "
            type="text"
            icon="el-icon-document-delete"
            size="small"
            @click.stop="handleCancel(scope.$index, scope.row)"
          >
            作废
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            v-if="scope.row.status === 2"
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="getPrint(scope.$index, scope.row)"
          >
            打印
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="deliveryOrderPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addDeliveryOrder,
  deleteDeliveryOrder,
  updateDeliveryOrder,
  selectDeliveryOrderInfo,
  selectDeliveryOrderList,
  reviewDeliveryOrder,
  cancelDeliveryOrder
} from '@/api/storage/deliveryOrder'
import { selectMaterialProductStockList } from '@/api/storage/materialProductStock'
import { selectNoticeList, selectNoticeByNo } from '@/api/sale/notice'
import { selectDictList } from '@/api/system/dict'

export default {
  data () {
    return {
      deliveryOrderDialogVisible: false,
      deliveryOrderFormTitle: '',
      deliveryOrderForm: {
        id: '',
        shippingOrderNo: '',
        shippingDate: '',
        customerName: '',
        receivingAddress: '',
        producingArea: '绍兴',
        remarks: '',
        reviewResult: '',
        deliveryOrderJson: '',
        taskId: '',
        type: '',
        orderNo: ''
      },
      deliveryOrderFormRules: {
        shippingOrderNo: [
          {
            required: true,
            message: '编号不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      deliveryOrderPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        shippingOrderNo: '',
        customerName: '',
        type: ''
      },
      orderDetailList: [],
      materialProductStockList: [],
      noticeList: [],
      typeList: []
    }
  },
  created () {
    selectDeliveryOrderList(this.searchForm).then((res) => {
      this.deliveryOrderPage = res
    })
    selectMaterialProductStockList({ type: 1 }).then(res => {
      this.materialProductStockList = res.list
    })
    selectNoticeList().then(res => {
      this.noticeList = res.list
    })
    selectDictList(2).then(res => {
      this.typeList = res
    })
  },
  methods: {
    deliveryOrderDialogClose () {
      this.$refs.deliveryOrderFormRef.resetFields()
      this.orderDetailList = []
    },
    deliveryOrderFormSubmit () {
      if (this.deliveryOrderFormTitle === '出货单详情') {
        this.deliveryOrderDialogVisible = false
        return
      }
      this.$refs.deliveryOrderFormRef.validate(async (valid) => {
        if (valid) {
          if (this.deliveryOrderFormTitle === '新增出货单') {
            this.deliveryOrderForm.id = ''
            this.deliveryOrderForm.status = 1
            this.deliveryOrderForm.deliveryOrderJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addDeliveryOrder(this.deliveryOrderForm)
          } else if (this.deliveryOrderFormTitle === '修改出货单') {
            this.deliveryOrderForm.deliveryOrderJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateDeliveryOrder(this.deliveryOrderForm)
          } else if (this.deliveryOrderFormTitle === '审核出货单') {
            this.deliveryOrderForm.status = 2
            await reviewDeliveryOrder(this.deliveryOrderForm)
          }
          this.deliveryOrderPage = await selectDeliveryOrderList(this.searchForm)
          this.deliveryOrderDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.deliveryOrderFormTitle = '新增出货单'
      this.deliveryOrderDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDeliveryOrder(row.id)
        if (
          this.deliveryOrderPage.list.length === 1
          && this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--
        }
        this.deliveryOrderPage = await selectDeliveryOrderList(this.searchForm)
      })
    },
    handleCancel (index, row) {
      this.$confirm('确认作废？', '提示', {
        type: 'warning'
      }).then(async () => {
        await cancelDeliveryOrder(row.id)
        if (
          this.deliveryOrderPage.list.length === 1
          && this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--
        }
        this.deliveryOrderPage = await selectDeliveryOrderList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.deliveryOrderFormTitle = '修改出货单'
      this.deliveryOrderDialogVisible = true
      this.selectDeliveryOrderInfoById(row.id)
    },
    handleReview (index, row) {
      this.deliveryOrderFormTitle = '审核出货单'
      this.deliveryOrderDialogVisible = true
      this.selectDeliveryOrderInfoById(row.id)
    },
    handleInfo (index, row) {
      this.deliveryOrderFormTitle = '出货单详情'
      this.deliveryOrderDialogVisible = true
      this.selectDeliveryOrderInfoById(row.id)
    },
    getPrint (index, row) {
      this.$router.push({ path: '/print', query: { id: row.id }})
    },
    selectDeliveryOrderInfoById (id) {
      selectDeliveryOrderInfo(id).then((res) => {
        this.deliveryOrderForm.id = res.id
        this.deliveryOrderForm.shippingOrderNo = res.shippingOrderNo
        this.deliveryOrderForm.shippingDate = res.shippingDate
        this.deliveryOrderForm.customerName = res.customerName
        this.deliveryOrderForm.receivingAddress = res.receivingAddress
        this.deliveryOrderForm.producingArea = res.producingArea
        this.deliveryOrderForm.remarks = res.remarks
        this.deliveryOrderForm.reviewResult = res.reviewResult
        this.deliveryOrderForm.taskId = res.taskId
        this.deliveryOrderForm.type = res.type
        this.orderDetailList = res.orderDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDeliveryOrderList(this.searchForm).then((res) => {
        this.deliveryOrderPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDeliveryOrderList(this.searchForm).then((res) => {
        this.deliveryOrderPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDeliveryOrderList(this.searchForm).then((res) => {
        this.deliveryOrderPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (
        this.checkPermission(['CC_DELIVERY_ORDER_REVIEW'])
        && row.status === 1
      ) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    materialProductStockChange (row) {
      if (row.productNo) {
        let materialProductStock = this.materialProductStockList.find(item => item.productNo === row.productNo)
        row.productName = materialProductStock.productName
        row.spec = materialProductStock.spec
        row.productionLicense = '浙食药监械生产许20120043号'
        if (materialProductStock.productName.indexOf('中性电极') > 0) {
          row.productRegistration = '浙械注准20162010478'
        }
        if (materialProductStock.productName.indexOf('手术电极') > 0) {
          row.productRegistration = '浙械注准20162010477'
        }
      } else {
        row.productName = ''
        row.spec = ''
      }
    },
    selectNotice (value) {
      this.deliveryOrderForm.customerName = ''
      this.deliveryOrderForm.receivingAddress = ''
      this.orderDetailList = []
      if (this.deliveryOrderForm.orderNo) {
        selectNoticeByNo(this.deliveryOrderForm.orderNo).then(res => {
          this.deliveryOrderForm.customerName = res.customerName
          this.deliveryOrderForm.receivingAddress = res.detailedAddress
          for (let item of res.detailList) {
            var productRegistration = ''
            if (item.productName.indexOf('中性电极') > 0) {
              productRegistration = '浙械注准20162010478'
            }
            if (item.productName.indexOf('手术电极') > 0) {
              productRegistration = '浙械注准20162010477'
            }
            this.orderDetailList.push({
              productNo: item.productNo,
              productName: item.productName,
              spec: item.spec,
              unit: item.unit,
              quantity: item.quantityBatch,
              productionLicense: '浙食药监械生产许20120043号',
              productRegistration: productRegistration
            })
          }
        })
      }
    }
  }
}
</script>

<style>
</style>
